import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import * as ds from '@bob/design-system';
import { styles as S } from '@bob/design-system';
import * as svg from './svg';
export function Partnership() {
  return __jsx(Section, null, __jsx(S.copy.Text4, {
    as: "p"
  }, "Bien ou Bien est ", __jsx("br", null), "fi\xE8rement propuls\xE9 par"), __jsx(LogoWrapper, null, __jsx(svg.Arrow, null), __jsx(svg.Maif, null), __jsx(svg.Ulule, null)));
}
var Section = styled.section.withConfig({
  displayName: "Partnership__Section",
  componentId: "sc-19ts7am-0"
})(["@media not screen and (", "){position:relative;padding:40px 0 85px;}@media screen and (", "){display:flex;align-items:center;justify-content:center;padding:30px 0 85px;}", "{@media not screen and (", "){margin-bottom:10px;text-align:center;}@media screen and (", "){max-width:200px;flex-grow:1;}}"], ds.breakpoint.TABLET, ds.breakpoint.TABLET, S.copy.Text4, ds.breakpoint.TABLET, ds.breakpoint.TABLET);
var LogoWrapper = styled.div.withConfig({
  displayName: "Partnership__LogoWrapper",
  componentId: "sc-19ts7am-1"
})(["display:flex;align-items:center;justify-content:center;"]);