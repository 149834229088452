import _extends from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["homepage"];
import React from "react";
var __jsx = React.createElement;
import styled, { css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import * as ds from '@bob/design-system';
import { styles as S } from '@bob/design-system';
import * as url from '@bob/url';

function GuideComponent(_ref) {
  var _homepage$guides$fash, _homepage$guides$fash2, _homepage$guides$fash3, _homepage$guides$alim, _homepage$guides$alim2, _homepage$guides$alim3, _homepage$guides$cosm, _homepage$guides$cosm2, _homepage$guides$cosm3, _homepage$guides$hous, _homepage$guides$hous2, _homepage$guides$hous3;

  var homepage = _ref.homepage,
      rest = _objectWithoutProperties(_ref, _excluded);

  var guideUrl = url.articles();

  var _useInView = useInView({
    triggerOnce: true,
    threshold: 0.25
  }),
      _useInView2 = _slicedToArray(_useInView, 2),
      ref = _useInView2[0],
      inView = _useInView2[1];

  return __jsx(Section, _extends({}, rest, {
    ref: ref,
    inView: inView
  }), __jsx(ds.Container, null, __jsx(S.heading.Title1, null, "Le Guide de la", __jsx("br", null), " consommation responsable")), __jsx(Body, null, __jsx(ds.Container, null, __jsx(Grid, null, __jsx(ds.Guide, {
    title: (_homepage$guides$fash = homepage.guides.fashion.name) !== null && _homepage$guides$fash !== void 0 ? _homepage$guides$fash : guideList.fashion.title,
    text: (_homepage$guides$fash2 = homepage.guides.fashion.description) !== null && _homepage$guides$fash2 !== void 0 ? _homepage$guides$fash2 : guideList.fashion.text,
    illustration: guideList.fashion.illustration,
    linkUrl: (_homepage$guides$fash3 = homepage.guides.fashion.url) !== null && _homepage$guides$fash3 !== void 0 ? _homepage$guides$fash3 : guideUrl.path + guideList.fashion.searchParams
  }), __jsx(ds.Guide, {
    title: (_homepage$guides$alim = homepage.guides.alimentation.name) !== null && _homepage$guides$alim !== void 0 ? _homepage$guides$alim : guideList.alimentation.title,
    text: (_homepage$guides$alim2 = homepage.guides.alimentation.description) !== null && _homepage$guides$alim2 !== void 0 ? _homepage$guides$alim2 : guideList.alimentation.text,
    illustration: guideList.alimentation.illustration,
    linkUrl: (_homepage$guides$alim3 = homepage.guides.alimentation.url) !== null && _homepage$guides$alim3 !== void 0 ? _homepage$guides$alim3 : guideUrl.path + guideList.alimentation.searchParams
  }), __jsx(ds.Guide, {
    title: (_homepage$guides$cosm = homepage.guides.cosmetic.name) !== null && _homepage$guides$cosm !== void 0 ? _homepage$guides$cosm : guideList.cosmetic.title,
    text: (_homepage$guides$cosm2 = homepage.guides.cosmetic.description) !== null && _homepage$guides$cosm2 !== void 0 ? _homepage$guides$cosm2 : guideList.cosmetic.text,
    illustration: guideList.cosmetic.illustration,
    linkUrl: (_homepage$guides$cosm3 = homepage.guides.cosmetic.url) !== null && _homepage$guides$cosm3 !== void 0 ? _homepage$guides$cosm3 : guideUrl.path + guideList.cosmetic.searchParams
  }), __jsx(ds.Guide, {
    title: (_homepage$guides$hous = homepage.guides.house.name) !== null && _homepage$guides$hous !== void 0 ? _homepage$guides$hous : guideList.house.title,
    text: (_homepage$guides$hous2 = homepage.guides.house.description) !== null && _homepage$guides$hous2 !== void 0 ? _homepage$guides$hous2 : guideList.house.text,
    illustration: guideList.house.illustration,
    linkUrl: (_homepage$guides$hous3 = homepage.guides.house.url) !== null && _homepage$guides$hous3 !== void 0 ? _homepage$guides$hous3 : guideUrl.path + guideList.house.searchParams
  })))));
}

var guideList = {
  fashion: {
    title: 'Guide de la mode',
    text: 'Parce qu’on veut vivre dans des vêtements confortables et jolis… Tout en cherchant des tissus et des fabrications éthiques!',
    illustration: __jsx(ds.illustrations.categories.Fashion, null),
    searchParams: '?categories=mode-et-accessoires'
  },
  alimentation: {
    title: 'Guide de l’alimentation',
    text: 'Parce qu’on aspire à manger des choses délicieuses… Aussi bonnes pour les producteurs et la planète que pour nous. ',
    illustration: __jsx(ds.illustrations.categories.Food, null),
    searchParams: "?categories=alimentation"
  },
  cosmetic: {
    title: 'Guide de la cosmétique',
    text: 'Parce que même si la beauté est intérieure, on aime bien utiliser deux ou trois flacons… Autant les choisir respectueux de l’environnement et de nos corps !',
    illustration: __jsx(ds.illustrations.categories.Hygiene, null),
    searchParams: "?categories=hygiene-beaute-et-bien-etre"
  },
  house: {
    title: 'Guide de la maison',
    text: 'Parce qu’on rêve d’une maison où l’on se sent bien… Sans contribuer à la déforestation de l’Amazonie ni à la pollution de notre home sweet home.',
    illustration: __jsx(ds.illustrations.categories.House, null),
    searchParams: "?categories=maison-et-jardin"
  }
};
export var Guide = styled(GuideComponent).withConfig({
  displayName: "Guide",
  componentId: "sc-6wxzv-0"
})([""]);
var Body = styled.section.withConfig({
  displayName: "Guide__Body",
  componentId: "sc-6wxzv-1"
})(["background:", ";margin-top:50px;padding:80px 0;"], ds.color.PINK);
var Grid = styled.div.withConfig({
  displayName: "Guide__Grid",
  componentId: "sc-6wxzv-2"
})(["display:grid;gap:50px;@media screen and (", "){gap:80px;grid-template-columns:repeat(2,1fr);}"], ds.breakpoint.TABLET);
var Section = styled.section.withConfig({
  displayName: "Guide__Section",
  componentId: "sc-6wxzv-3"
})(["padding-top:50px;", "{opacity:0;transform:translateX(-100%);transition-delay:400ms;transition-duration:500ms;transition-property:opacity,transform;transition-timing-function:ease-in-out;", "}", "{opacity:0;transform:translateX(15px);transition-duration:350ms;transition-property:opacity,transform;transition-timing-function:ease-in-out;}", ":nth-child(1){transition-delay:1000ms;", "}", ":nth-child(2){transition-delay:1300ms;", "}", ":nth-child(3){transition-delay:1600ms;", "}", ":nth-child(4){transition-delay:1900ms;", "}"], Body, function (_ref2) {
  var inView = _ref2.inView;
  return inView && css(["opacity:1;transform:translateX(0%);"]);
}, ds.Guide, ds.Guide, function (_ref3) {
  var inView = _ref3.inView;
  return inView && css(["opacity:1;transform:translateY(0px);"]);
}, ds.Guide, function (_ref4) {
  var inView = _ref4.inView;
  return inView && css(["opacity:1;transform:translateY(0px);"]);
}, ds.Guide, function (_ref5) {
  var inView = _ref5.inView;
  return inView && css(["opacity:1;transform:translateY(0px);"]);
}, ds.Guide, function (_ref6) {
  var inView = _ref6.inView;
  return inView && css(["opacity:1;transform:translateY(0px);"]);
});