import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'

export type GridProps = {
    size: number
}

const BaseGrid = styled.div`
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 10px;
    padding-right: 10px;

    @media not screen and (${breakpoint.LAPTOP}) {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        padding-bottom: 10px;
    }
    @media screen and (${breakpoint.MOBILE_L}) {
        margin-left: -40px;
        margin-right: -40px;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media screen and (${breakpoint.LAPTOP}) {
        display: grid;
        margin: 0;
        padding: 0;
        gap: 32px;
    }
    @media screen and (${breakpoint.LAPTOP_L}) {
        gap: 36px;
    }
`

export const Grid = styled(BaseGrid)`
    @media screen and (${breakpoint.LAPTOP}) {
        grid-template-columns: repeat(4, 1fr);
    }
`

export const ScrollableGrid = styled(BaseGrid)<GridProps>`
    grid-auto-flow: column;
    grid-auto-columns: 220px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    @media screen and (${breakpoint.LAPTOP}) {
        grid-auto-flow: column;
        grid-auto-columns: ${props => props.size + 'px'};
    }
`

export const GridItem = styled.div`
    @media not screen and (${breakpoint.LAPTOP}) {
        padding: 0 10px;
    }
`
