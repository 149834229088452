import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";
import React from "react";
var __jsx = React.createElement;
import Link from 'next/link';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';
import * as ds from '@bob/design-system';
import { styles as S } from '@bob/design-system';
import * as url from '@bob/url';
import { BrushTitleAnimated } from '../../../../components/BrushTitleAnimated';
export function Manifesto() {
  var inViewOptions = {
    threshold: 0.0,
    initialInView: false,
    triggerOnce: true
  };

  var _useInView = useInView(inViewOptions),
      _useInView2 = _slicedToArray(_useInView, 2),
      $manifesto = _useInView2[0],
      inView = _useInView2[1];

  return __jsx(Wrapper, {
    ref: $manifesto
  }, __jsx(BrushTitleAnimated, {
    brushes: [{
      wordIndex: 5,
      comp: Brush,
      props: {
        brushId: 'pink'
      }
    }, {
      wordIndex: 16,
      comp: Brush,
      props: {
        brushId: 'green'
      }
    }, {
      wordIndex: 37,
      comp: Brush,
      props: {
        brushId: 'yellow'
      }
    }],
    Component: S.heading.Title2,
    inView: inView,
    tag: "h4",
    text: "Chez Bien ou Bien, comme vous, on a envie que le monde de demain soit vraiment bien. Alors on a regroup\xE9 ici des biens tout simplement bien : des produits du quotidien, en version plus durable et solidaire."
  }), __jsx(Link, {
    prefetch: false,
    href: "".concat(url.manifesto().path),
    passHref: true
  }, __jsx(ds.ButtonAsLink, {
    autoSized: true
  }, "Lire notre manifeste")));
}
export var BrushPinkBackground = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush5, {
  width: 482,
  height: 91,
  fill: ds.color.PINK
});
export var BrushGreenBackground = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush2, {
  width: 482,
  height: 91,
  fill: ds.color.LIGHT_GREEN
});
export var BrushYellowBackground = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush4, {
  width: 482,
  height: 91,
  fill: ds.color.YELLOW
});
export var Brush = styled.span.withConfig({
  displayName: "Manifesto__Brush",
  componentId: "sc-px0ym1-0"
})(["display:inline-block;position:relative;z-index:-3;@keyframes brush-slide{from{clip-path:inset(0 100% 0 0);}to{clip-path:inset(0 0 0 0);}}&::before{position:absolute;content:'';display:block;top:-0.5em;left:-1em;bottom:-0.5em;right:-0.75em;}&::before{z-index:-3;background-image:", ";background-size:150%;background-repeat:no-repeat;background-position:center;animation:brush-slide 0.4s ease-in-out both;animation-play-state:paused;animation-delay:", ";", "}"], function (_ref) {
  var brushId = _ref.brushId;
  return brushId === 'pink' ? "url('".concat(BrushPinkBackground, "')") : brushId === 'green' ? "url('".concat(BrushGreenBackground, "')") : "url('".concat(BrushYellowBackground, "')");
}, function (_ref2) {
  var _ref2$delay = _ref2.delay,
      delay = _ref2$delay === void 0 ? 0 : _ref2$delay;
  return "calc(0.1s * var(--word-index, 0) + 0.2s + ".concat(delay, "s)");
}, function (_ref3) {
  var inView = _ref3.inView;
  return inView && css(["animation-play-state:running;"]);
});
var Wrapper = styled.div.withConfig({
  displayName: "Manifesto__Wrapper",
  componentId: "sc-px0ym1-1"
})(["text-align:center;margin-bottom:100px;margin-top:60px;max-width:1184px;", "{margin-top:70px;}@media screen and (", "){margin-bottom:280px;}"], ds.ButtonAsLink, ds.breakpoint.LAPTOP);