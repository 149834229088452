var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import * as ds from '@bob/design-system';
import { Controls } from '../../../../../components/CriteriaSlider/Controls';
import { Tile } from '../../../../../components/CriteriaSlider/Tile';
import { useInfiniteCarousel } from '../../../../../hooks/useInfiniteCarousel';
export function CriteriaSlider(_ref) {
  var data = _ref.data,
      activeIndex = _ref.activeIndex,
      setActiveIndex = _ref.setActiveIndex;

  var _useInfiniteCarousel = useInfiniteCarousel({
    data: data,
    activeIndex: activeIndex,
    elementWidth: 255,
    setActiveIndex: setActiveIndex
  }),
      ref = _useInfiniteCarousel.ref,
      renderedList = _useInfiniteCarousel.renderedList,
      onClickElement = _useInfiniteCarousel.onClickElement,
      onScroll = _useInfiniteCarousel.onScroll,
      onScrollOneStepLeft = _useInfiniteCarousel.onScrollOneStepLeft,
      onScrollOneStepRight = _useInfiniteCarousel.onScrollOneStepRight;

  return __jsx(Wrapper, null, __jsx(InfiniteList, {
    ref: ref,
    onScroll: onScroll
  }, renderedList.map(function (tile, i) {
    return __jsx(Tile, {
      key: i,
      isFocused: activeIndex === tile.realIndex,
      onFocus: function onFocus() {
        return onClickElement(i, tile.realIndex);
      },
      title: tile.title,
      svg: tile.svg
    });
  })), __jsx(Controls, {
    onClickLeft: onScrollOneStepLeft,
    onClickRight: onScrollOneStepRight
  }));
}
var Wrapper = styled.div.withConfig({
  displayName: "CriteriaSlider__Wrapper",
  componentId: "sc-1qzkw9r-0"
})(["position:relative;"]);
var InfiniteList = styled.ul.withConfig({
  displayName: "CriteriaSlider__InfiniteList",
  componentId: "sc-1qzkw9r-1"
})(["white-space:nowrap;overflow-y:hidden;overflow-x:hidden;width:100%;scroll-snap-type:x mandatory;background-color:", ";z-index:2;height:370px;@media screen and (", "){height:520px;overflow-x:hidden;margin:auto;}&::-webkit-scrollbar{display:none;}-ms-overflow-style:none;scrollbar-width:none;"], ds.color.PINK, ds.breakpoint.TABLET);