import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray";
import _extends from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import Link from 'next/link';
import dynamic from 'next/dynamic';
var Snowfall = dynamic(function () {
  return import('react-snowfall');
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('react-snowfall')];
    }
  }
});
import * as ds from '@bob/design-system';
import { styles as S } from '@bob/design-system';
import * as money from '@bob/money';
import * as url from '@bob/url';
import * as utils from '@bob/utils';
import * as storeView from '../../../store';
import { IS_XMAS } from './config';
import { GiftCard } from './GiftCard';
import { Partnership } from './Partnership';
import { Section } from './Section';
export function HighlightProducts(_ref) {
  var products = _ref.products,
      categoryList = _ref.categoryList;
  return __jsx(ds.Container, null, __jsx(SectionWithCenteredTitle, {
    title: IS_XMAS ? // HACK: this as="h1" is a temporary fix. We should find a cleaner,
    // more generic way of creating a "h1" element in Sections
    __jsx(XmasTitle, {
      as: "h1"
    }, "Des ", __jsx(XmasBrush, null, "cadeaux durables"), " pour toutes les f\xEAtes") : __jsx(React.Fragment, null, "Vos produits ", __jsx(Brush, null, "durables"), " pour la vie de tous les jours"),
    footer: IS_XMAS ? __jsx(ButtonContainer, null, __jsx(Link, {
      prefetch: false,
      href: url.article({
        title: '',
        slug: 'noel-durable-conseils-idees'
      }).path,
      passHref: true
    }, __jsx(ds.ButtonAsLink, {
      autoSized: true
    }, "Nos s\xE9lections pour No\xEBl")), __jsx(Link, {
      prefetch: false,
      href: url.giftIdeas().path,
      passHref: true
    }, __jsx(ds.ButtonAsLink, {
      outlined: true,
      autoSized: true
    }, "Trouver le cadeau parfait \uD83C\uDF81"))) : __jsx(Link, {
      prefetch: false,
      href: url.products({
        title: ''
      }).path,
      passHref: true
    }, __jsx(ds.ButtonAsLink, {
      autoSized: true
    }, "Voir tous les produits"))
  }, IS_XMAS && __jsx(DesktopXmasBackground, null, __jsx(Snowfall, {
    snowflakeCount: 202,
    speed: [0, 0.5],
    wind: [-0.5, 1],
    radius: [0.5, 2.5]
  }), __jsx("img", {
    alt: "fond de no\xEBl",
    src: "/assets/xmas/xmas-bg-1x.png",
    srcSet: "/assets/xmas/xmas-bg-1x.png 1x, /assets/xmas/xmas-bg-2x.png 2x, /assets/xmas/xmas-bg-3x.png 3x",
    height: 376,
    width: 1283
  })), __jsx(LargeLaptopCarousel, null, pages(4, products)), __jsx(LaptopCarousel, null, pages(3, products)), __jsx(TabletCarousel, null, pages(2, products)), __jsx(MobileCarousel, null, pages(1, products))), __jsx(GiftCard, null), __jsx(Partnership, null), __jsx(storeView.CenteredTitle, null, "Toutes les cat\xE9gories"), __jsx(storeView.CategoryList, null, categoryList.map(function (category, i) {
    var Illustration = ds.illustrations.categories[category.icon];
    return __jsx(Link, {
      key: category.name,
      href: "".concat(url.products({
        title: ''
      }).path, "?category=").concat(category.slug),
      prefetch: false,
      passHref: true
    }, __jsx(storeView.Item, {
      order: i + 1
    }, __jsx(ds.CategoryLink, _extends({}, category, {
      blob: utils.blob.blob(),
      illustration: __jsx(Illustration, null),
      vertical: false
    }))));
  })));
}

function pages(size, products) {
  return paginate(products, size).map(function (page, i) {
    return __jsx(Grid, {
      key: i
    }, page.map(function (product) {
      var _product$criteria = product.criteria,
          environmentalCriteria = _product$criteria.environmental,
          socialCriteria = _product$criteria.social;
      return __jsx(Item, {
        key: product.id
      }, __jsx(ds.ProductCard, {
        brand: product.brand,
        compareAtPriceRange: product.compareAtPriceRange ? [money.format(product.compareAtPriceRange[0]), money.format(product.compareAtPriceRange[1])] : undefined,
        criteria: [].concat(_toConsumableArray(environmentalCriteria.map(pillarMapper)), _toConsumableArray(socialCriteria.map(pillarMapper))),
        href: product.href,
        picture: _objectSpread(_objectSpread({}, product.picture), {}, {
          nativeLazyLoad: true
        }),
        priceRange: [money.format(product.priceRange[0]), money.format(product.priceRange[1])],
        title: product.title
      }));
    }));
  });
}

function pillarMapper(pillar) {
  return {
    icon: utils.symbols.getCriterionSymbolName(pillar),
    name: pillar.name
  };
}

function paginate(products, size) {
  return products.reduce(function (highlights, product, i) {
    var lastPage = highlights[highlights.length - 1];
    lastPage.push(product);

    if (lastPage.length === size && i < products.length - 1) {
      highlights.push([]);
    }

    return highlights;
  }, [[]]);
}

var SectionWithCenteredTitle = styled(Section).withConfig({
  displayName: "HighlightProducts__SectionWithCenteredTitle",
  componentId: "sc-lyr4tq-0"
})(["padding-bottom:85px;position:relative;", "{text-align:center;}"], S.heading.Title1);
var Grid = styled.div.withConfig({
  displayName: "HighlightProducts__Grid",
  componentId: "sc-lyr4tq-1"
})([""]);
var Item = styled.div.withConfig({
  displayName: "HighlightProducts__Item",
  componentId: "sc-lyr4tq-2"
})(["@media not screen and (", "){width:245px;}", "{margin-top:8px;}"], ds.breakpoint.TABLET, S.card.criterion.Criteria);
var LargeLaptopCarousel = styled(ds.Carousel).withConfig({
  displayName: "HighlightProducts__LargeLaptopCarousel",
  componentId: "sc-lyr4tq-3"
})(["@media not screen and (", "){display:none;}", "{display:grid;grid-template-columns:repeat(4,1fr);}", "{padding:0 10px;}"], ds.breakpoint.LAPTOP_L, Grid, Item);
var LaptopCarousel = styled(ds.Carousel).withConfig({
  displayName: "HighlightProducts__LaptopCarousel",
  componentId: "sc-lyr4tq-4"
})(["@media not screen and (", "){display:none;}@media screen and (", "){display:none;}", "{display:grid;grid-template-columns:repeat(3,1fr);}", "{padding:0 10px;}"], ds.breakpoint.LAPTOP, ds.breakpoint.LAPTOP_L, Grid, Item);
var TabletCarousel = styled(ds.Carousel).withConfig({
  displayName: "HighlightProducts__TabletCarousel",
  componentId: "sc-lyr4tq-5"
})(["@media not screen and (", "){display:none;}@media screen and (", "){display:none;}", "{display:grid;grid-template-columns:repeat(2,1fr);}", "{padding:0 10px;}"], ds.breakpoint.TABLET, ds.breakpoint.LAPTOP, Grid, Item);
var MobileCarousel = styled(ds.Carousel).withConfig({
  displayName: "HighlightProducts__MobileCarousel",
  componentId: "sc-lyr4tq-6"
})(["@media screen and (", "){display:none;}", "{display:flex;justify-content:center;}", "{padding:0 10px;}"], ds.breakpoint.TABLET, Grid, Item);
var slide = keyframes(["from{left:-1em;}to{left:130%;}"]);
var scaleX = keyframes(["from{transform:scaleX(0);}to{transform:scaleX(1);}"]);
var BrushBackground = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush5, {
  width: 482,
  height: 91,
  fill: ds.color.PINK
});
var XmasBrushBackground = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush6, {
  width: 250,
  height: 30,
  fill: ds.color.PINE_GREEN_2
});
var BaseBrush = styled.span.withConfig({
  displayName: "HighlightProducts__BaseBrush",
  componentId: "sc-lyr4tq-7"
})(["display:inline-block;position:relative;&::after{background-position:center;background-repeat:no-repeat;background-size:100%;bottom:-0.5em;content:'';display:block;left:-1.25em;position:absolute;right:-1.25em;top:-0.5em;z-index:-2;}"]);
var SlideBrush = styled(BaseBrush).withConfig({
  displayName: "HighlightProducts__SlideBrush",
  componentId: "sc-lyr4tq-8"
})(["&::before{position:absolute;content:'';display:block;top:-0.5em;left:-1.25em;bottom:-0.5em;right:-1.25em;}&::before{z-index:-1;animation:", " 0.4s ease-in-out forwards;animation-delay:1.5s;}"], slide);
var ScaleBrush = styled(BaseBrush).withConfig({
  displayName: "HighlightProducts__ScaleBrush",
  componentId: "sc-lyr4tq-9"
})(["&::after{transform-origin:left;transform:scaleX(0);animation:", " 0.4s ease forwards;animation-delay:1.5s;}"], scaleX);
var XmasBrush = styled(BaseBrush).withConfig({
  displayName: "HighlightProducts__XmasBrush",
  componentId: "sc-lyr4tq-10"
})(["&::after{background-image:url('", "');}&::before{background:", ";}"], XmasBrushBackground, ds.color.LIGHT_GREEN);
var Brush = styled(SlideBrush).withConfig({
  displayName: "HighlightProducts__Brush",
  componentId: "sc-lyr4tq-11"
})(["&::after{background-image:url('", "');}&::before{background:", ";}"], BrushBackground, ds.color.SAND_1);
var DesktopXmasBackground = styled.div.withConfig({
  displayName: "HighlightProducts__DesktopXmasBackground",
  componentId: "sc-lyr4tq-12"
})(["display:none;@media screen and (", "){background-color:", ";border-radius:8px 8px 0 0;display:initial;position:absolute;top:0;width:100%;z-index:-3;}"], ds.breakpoint.TABLET, ds.color.LIGHT_GREEN);
var XmasTitle = styled(ds.styles.heading.Title2).withConfig({
  displayName: "HighlightProducts__XmasTitle",
  componentId: "sc-lyr4tq-13"
})(["color:", ";margin:20px auto 60px;max-width:350px;@media screen and (", "){margin:10px auto 20px;max-width:634px;}"], ds.color.WHITE, ds.breakpoint.TABLET);
var ButtonContainer = styled.div.withConfig({
  displayName: "HighlightProducts__ButtonContainer",
  componentId: "sc-lyr4tq-14"
})(["a{margin:10px;}"]);