import React from "react";
var __jsx = React.createElement;
import { NextSeo } from 'next-seo';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
var Snowfall = dynamic(function () {
  return import('react-snowfall');
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('react-snowfall')];
    }
  }
});
import * as ds from '@bob/design-system';
import * as url from '@bob/url';
import { SITE_URL } from '../../../../constants';
import { IS_XMAS } from './config';
import { Actus } from './Actus';
import { Criteria } from './Criteria';
import { Guide } from './Guide';
import { HomeTestimonials } from './HomeTestimonials';
import { Manifesto } from './Manifesto';
import { Moments } from './Moment';
import { HighlightProducts } from './HighlightProducts';
export function Homepage(_ref) {
  var homepage = _ref.homepage;
  return __jsx(React.Fragment, null, __jsx(NextSeo, {
    title: "Bien ou Bien : achetez des biens tout simplement bien",
    description: "D\xE9couvrez des produits bien pour la plan\xE8te et les humains",
    canonical: SITE_URL + url.homepage().path,
    openGraph: {
      title: 'Bien ou Bien : achetez des biens tout simplement bien',
      description: 'Découvrez des produits bien pour la planète et les humains',
      url: SITE_URL,
      images: [{
        alt: 'Bien ou Bien, le magasin où acheter des biens tout simplement bien',
        url: SITE_URL + '/assets/bob_og_image.png'
      }]
    },
    robotsProps: {
      maxImagePreview: 'large'
    }
  }), IS_XMAS && __jsx(MobileXmasBackground, null, __jsx("img", {
    alt: "fond de no\xEBl",
    src: "/assets/xmas/mobile-xmas-bg-1x.png",
    srcSet: "/assets/xmas/mobile-xmas-bg-1x.png 1x, /assets/xmas/mobile-xmas-bg-2x.png 2x, /assets/xmas/mobile-xmas-bg-3x.png 3x",
    height: 1665,
    width: 960
  }), __jsx(Snowfall, {
    snowflakeCount: 202,
    speed: [0, 0.5],
    wind: [-0.5, 1],
    radius: [0.5, 2.5]
  })), __jsx(Wrapper, null, __jsx(HighlightProducts, {
    products: homepage.highlightProducts,
    categoryList: homepage.categoryList
  }), __jsx(ds.Container, null, __jsx(Manifesto, null)), __jsx(Criteria, {
    homepage: homepage
  }), __jsx(ds.Container, null, __jsx(HomeTestimonials, {
    homepage: homepage
  })), __jsx(Moments, {
    homepage: homepage
  }), __jsx(ds.Container, null, __jsx(Actus, {
    homepage: homepage
  })), __jsx(Guide, {
    homepage: homepage
  })));
}
var Wrapper = styled.div.withConfig({
  displayName: "Homepage__Wrapper",
  componentId: "sc-1j1k1s9-0"
})(["width:100%;overflow-x:hidden;position:relative;"]);
var MobileXmasBackground = styled.figure.withConfig({
  displayName: "Homepage__MobileXmasBackground",
  componentId: "sc-1j1k1s9-1"
})(["align-items:flex-end;background-color:", ";display:flex;max-height:340px;overflow:hidden;position:absolute;top:-16px;height:100%;width:100%;z-index:-2;img{flex:none;position:relative;top:105px;}@media screen and (", "){display:none;}"], ds.color.LIGHT_GREEN, ds.breakpoint.TABLET);