import styled from 'styled-components'

import * as money from '@bob/money'

import { CriterionList, Criteria } from './CriterionList'
import * as icons from '../../svg/icons'
import * as S from '../../styles'
import { useLinkWrapper } from '../../hooks/useLinkWrapper'
import { LazyImage } from '../LazyImage'

export type CuratedProductCardProps = {
    brand: string
    compareAtPriceRange?: [number, number] | null
    criteria: Criteria
    href: string
    picture: {
        alt?: string | null
        src?: string | null
        srcSet?: string | null
        width?: number | null
        height?: number | null
        nativeLazyLoad?: boolean
    }
    priceRange?: [number, number]
    title: string
}

function CuratedProductCardComponent({
    brand,
    compareAtPriceRange,
    criteria,
    href,
    picture,
    priceRange,
    title,
    ...rest
}: CuratedProductCardProps): React.ReactElement {
    const LinkWrapper = useLinkWrapper()

    const price = priceRange
        ? priceRange[0] === priceRange[1]
            ? money.format(priceRange[0])
            : `à partir de ${money.format(priceRange[0])}`
        : '-'

    const compareAtPrice = compareAtPriceRange
        ? compareAtPriceRange[0] === compareAtPriceRange[1]
            ? money.format(compareAtPriceRange[0])
            : `à partir de ${money.format(compareAtPriceRange[0])}`
        : null

    const lowestPrice = priceRange ? priceRange[0] : 0
    const lowestCompareAtPrice = compareAtPriceRange ? compareAtPriceRange[0] : 0
    const displayDiscountPrice = compareAtPrice && lowestPrice < lowestCompareAtPrice

    return (
        <LinkWrapper href={href} passHref>
            <S.card.curatedProduct.Wrapper>
                <S.card.curatedProduct.Link {...rest}>
                    <LazyImage nativeLazyLoad={picture.nativeLazyLoad}>
                        <S.card.curatedProduct.Image
                            src={picture.src ?? ''}
                            srcSet={picture?.srcSet ?? ''}
                            alt={picture.alt || title}
                            width={picture.width ?? undefined}
                            height={picture.height ?? undefined}
                        />
                    </LazyImage>
                </S.card.curatedProduct.Link>
                <S.card.curatedProduct.Data>
                    <CriterionList maxToShow={3} criteria={criteria} />
                    <S.card.curatedProduct.Title>
                        {title}
                        <icons.ArrowRight size={27} />
                    </S.card.curatedProduct.Title>
                    <S.card.curatedProduct.Bottom>
                        <S.copy.Text1>{brand}</S.copy.Text1>
                        <S.copy.Text1>
                            <strong>{price}</strong>
                            {displayDiscountPrice && (
                                <S.card.curatedProduct.CompareAtPrice>
                                    {compareAtPrice}
                                </S.card.curatedProduct.CompareAtPrice>
                            )}
                        </S.copy.Text1>
                    </S.card.curatedProduct.Bottom>
                </S.card.curatedProduct.Data>
            </S.card.curatedProduct.Wrapper>
        </LinkWrapper>
    )
}
export const CuratedProductCard = styled(CuratedProductCardComponent)``
