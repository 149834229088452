import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray";
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import * as ds from '@bob/design-system';
import { styles as S } from '@bob/design-system';
import * as url from '@bob/url';
import { RotativeCard } from '../../../../../components/CriteriaSlider/RotativeCard';
import { CriteriaSlider } from './CriteriaSlider';

function CriteriaComponent(_ref) {
  var homepage = _ref.homepage;
  var citeria = React.useMemo(function () {
    return _toConsumableArray(Object.values(homepage.pillars).flatMap(function (criterion) {
      return criterion;
    })).sort(function (tagA, tagB) {
      if (tagA.extra.order === null && tagB.extra.order === null) {
        return 0;
      }

      if (tagA.extra.order === null) {
        return 1;
      }

      if (tagB.extra.order === null) {
        return -1;
      }

      return Number(tagA.extra.order) - Number(tagB.extra.order);
    });
  }, [homepage]);
  var displayedTags = React.useMemo(function () {
    return citeria.map(function (criterion) {
      return {
        title: criterion.name,
        imgSrc: criterion.imageCover.src,
        svg: criterion.extra.icon
      };
    });
  }, [citeria]);

  var _React$useState = React.useState(),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeIndex = _React$useState2[0],
      setActiveIndex = _React$useState2[1];

  return __jsx(CriteriaWrapper, null, __jsx(HugeTitle, null, "nos crit\xE8res"), __jsx(RotativeCard, {
    activeIndex: activeIndex,
    data: displayedTags
  }), __jsx(CriteriaSlider, {
    activeIndex: activeIndex,
    setActiveIndex: setActiveIndex,
    data: displayedTags
  }), activeIndex !== undefined && __jsx(Information, null, __jsx(S.copy.Text5, null, citeria[activeIndex].descriptionShort), __jsx(Link, {
    prefetch: false,
    href: "".concat(url.manifesto().path, "#nos-criteres"),
    passHref: true
  }, __jsx(ds.ButtonAsLink, {
    autoSized: true
  }, "D\xE9couvrez nos crit\xE8res de s\xE9lection"))));
}

var CriteriaWrapper = styled.div.withConfig({
  displayName: "Criteria__CriteriaWrapper",
  componentId: "sc-1ao3re3-0"
})(["margin-bottom:20px;margin-top:0px;position:relative;@media screen and (", "){margin-bottom:50px;margin-top:180px;}@media screen and (", "){margin-bottom:50px;margin-top:200px;}"], ds.breakpoint.LAPTOP, ds.breakpoint.LAPTOP_L);
var Information = styled.div.withConfig({
  displayName: "Criteria__Information",
  componentId: "sc-1ao3re3-1"
})(["display:flex;flex-direction:column;align-items:center;padding:0 10px;", "{text-align:center;margin-top:80px;margin-bottom:30px;}@media screen and (", "){padding:0;", "{width:515px;margin-top:53px;margin-bottom:60px;}}"], S.copy.Text5, ds.breakpoint.LAPTOP, S.copy.Text5);
var HugeTitle = styled.span.withConfig({
  displayName: "Criteria__HugeTitle",
  componentId: "sc-1ao3re3-2"
})(["font-family:", ";font-size:70px;position:absolute;top:0;left:50%;transform:translateX(-50%);width:max-content;top:-50px;z-index:2;pointer-events:none;@media screen and (", "){font-size:200px;top:-180px;}@media screen and (", "){font-size:243px;top:-210px;}"], ds.font.PRIMARY, ds.breakpoint.LAPTOP, ds.breakpoint.LAPTOP_L);
export var Criteria = styled(CriteriaComponent).withConfig({
  displayName: "Criteria",
  componentId: "sc-1ao3re3-3"
})([""]);