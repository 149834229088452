import styled, { css } from 'styled-components'
import * as breakpoint from '../../constants/breakpoints'
import * as color from '../../constants/colors'
import * as copy from './../copy'
import * as heading from './../heading'

export const Informations = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    ${copy.TextPrice} {
        color: ${color.PINE_GREEN_1};
        margin-top: 5px;
        font-size: 18px;

        @media screen and (${breakpoint.TABLET}) {
            margin-top: 4px;
        }
    }

    ${heading.TitleCard} {
        @media not screen and (${breakpoint.TABLET}) {
            font-size: 16px;
            margin-top: 5px;
        }
    }
    ${copy.TextInfo} {
        margin-top: 10px;

        @media screen and (${breakpoint.TABLET}) {
            margin-top: 4px;
        }
    }

    ${copy.Text1} {
        margin-top: 2px;

        @media screen and (${breakpoint.TABLET}) {
            margin-top: 5px;
        }
    }

    @media screen and (${breakpoint.TABLET}) {
        margin-left: 0px;
        margin-top: 20px;

        @media screen and (${breakpoint.TABLET}) {
            margin-top: 4px;
        }
    }
`

export const Wrapper = styled.a<{ compact: boolean; sober: boolean }>`
    width: 100%;

    ${({ sober }) =>
        !sober &&
        css`
            @media screen and (${breakpoint.TABLET}) {
                flex-direction: column;
            }
        `}

    ${copy.Text4} {
        opacity: 0.7;
        margin-top: 15px;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    ${({ compact }) =>
        compact &&
        css`
            display: flex;

            ${Informations} {
                padding: 10px;
            }

            ${heading.TitleCard} {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        `}
`

export const Prices = styled.div`
    display: flex;
    align-items: center;
`

export const CompareAtPrice = styled.span`
    position: relative;
    top: 3px;
    margin-left: 18px;
    color: ${color.GRAY_1};
    text-decoration: line-through;
`
