import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import * as ds from '@bob/design-system';
export function Controls(_ref) {
  var onClickLeft = _ref.onClickLeft,
      onClickRight = _ref.onClickRight;
  return __jsx(Wrapper, null, __jsx(ds.CircularButton, {
    outlined: true,
    onClick: onClickLeft
  }, __jsx(ds.icons.ChevronLeft, {
    size: 32
  })), __jsx(ds.CircularButton, {
    outlined: true,
    onClick: onClickRight
  }, __jsx(ds.icons.ChevronRight, {
    size: 32
  })));
}
var Wrapper = styled.div.withConfig({
  displayName: "Controls__Wrapper",
  componentId: "sc-781jwv-0"
})(["display:none;", "{margin-top:30px;}", ":nth-child(1){margin-left:8%;}", ":nth-child(2){margin-right:8%;}@media screen and (", "){display:flex;justify-content:space-between;}"], ds.CircularButton, ds.CircularButton, ds.CircularButton, ds.breakpoint.TABLET);