function getScrollCenterPosition(ref, element, marge) {
  if (ref.current === null) return;
  var elementBound = element.getBoundingClientRect();
  var parentBound = ref.current.getBoundingClientRect(); // Ce calcul permet de calculer la position du scroll
  // pour centrer l'élément dans la barre scrollable
  // pour cela il fallait prendre en compte la taille du parent
  // la taille de l'élément cliqué et ses marges
  // afin de trouver le centre
  // diviser par deux permet de prendre le centre de chacun
  // il faut y ajouter la position initial du scroll a la position
  // x de l'élément cliquer et enfin enlever les marges totales

  var scrollPosition = ref.current.scrollLeft + elementBound.x - marge - parentBound.width / 2 + elementBound.width / 2;
  return scrollPosition;
}

export function selectTitle(element, refTitleList) {
  var _getScrollCenterPosit;

  if (refTitleList.current === null) return;
  var style = window.getComputedStyle(element);
  var marge = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
  var scrollPosition = (_getScrollCenterPosit = getScrollCenterPosition(refTitleList, element, marge)) !== null && _getScrollCenterPosit !== void 0 ? _getScrollCenterPosit : 0;
  refTitleList.current.scrollLeft = scrollPosition;
}