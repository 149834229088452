import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as color from '../constants/colors'
import * as brush from '../svg/brush'
import { toDataUrl } from '../utils/svg'
import * as heading from './heading'

const blobBackgroundTitle = toDataUrl(brush.full.FullBrush2, {
    width: 274,
    height: 82,
    fill: color.SAND_1
})

export const Title = styled(heading.Title1)`
    position: relative;
    display: inline-block;
    line-height: 1;
    margin-bottom: 20px;

    @media screen and (${breakpoint.MOBILE_L}) {
        font-size: 45px;
    }

    &::before,
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: -20px;
        left: -50px;
        width: 274px;
        height: 82px;
    }

    &::after {
        z-index: 1;
        background-color: ${color.PINK};
        transition: transform 0.3s ease-in-out;
    }

    &::before {
        z-index: 0;
        background-image: url('${blobBackgroundTitle}');
        background-repeat: no-repeat;
    }
`

export const TitleContent = styled.span`
    position: relative;
    z-index: 2;
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 85px 1fr;
    gap: 20px;
    text-align: left;

    @media screen and (${breakpoint.MOBILE_L}) {
        grid-template-columns: 130px 1fr;
        gap: 30px;
    }
`

export const Text = styled.div`
    padding-top: 10px;
    margin-bottom: 20px;

    @media screen and (${breakpoint.MOBILE_L}) {
        padding-top: 20px;
    }
`
export const Illustration = styled.div`
    svg {
        width: 100%;
        height: auto;
    }
`

export const Wrapper = styled.a`
    display: block;
    text-align: center;

    @media screen and (${breakpoint.LAPTOP}) {
        &:hover {
            ${Title} {
                &::after {
                    transform: translateX(100%);
                }
            }
        }
    }
`
