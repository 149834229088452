import * as React from 'react'
import styled, { css } from 'styled-components'

import * as S from '../../styles'
import * as color from '../../constants/colors'
import * as breakpoint from '../../constants/breakpoints'
import { useLinkWrapper } from '../../hooks/useLinkWrapper'
import { CriterionList, Criteria } from './CriterionList'
import { Picture } from '../Picture'

export type ProductCardProps = {
    className?: string
    brand?: string
    compareAtPriceRange?: [string, string]
    criteria?: Criteria
    description?: string
    href?: string
    openInNewTab?: boolean
    picture: {
        alt?: string | null
        src?: string | null
        srcSet?: string | null
        nativeLazyLoad?: boolean
    }
    priceRange?: [string, string]
    title: string
    variantTitle?: string
    compact?: boolean
}

function ProductCardComponent({
    className,
    brand,
    compareAtPriceRange,
    criteria,
    description,
    href,
    openInNewTab,
    picture,
    priceRange,
    title,
    variantTitle,
    compact = false
}: ProductCardProps): React.ReactElement {
    const LinkWrapper = useLinkWrapper()

    const price = priceRange
        ? priceRange[0] === priceRange[1]
            ? `${priceRange[0]}`
            : `à partir de ${priceRange[0]}`
        : '-'

    const compareAtPrice = compareAtPriceRange
        ? compareAtPriceRange[0] === compareAtPriceRange[1]
            ? `${compareAtPriceRange[0]}`
            : `à partir de ${compareAtPriceRange[0]}`
        : null

    const sober = !criteria && !brand

    // TODO: Handle this properly...
    const lowestPrice = priceRange
        ? parseFloat(priceRange[0].toString().replace(',', '.'))
        : 0
    const lowestCompareAtPrice = compareAtPriceRange
        ? parseFloat(compareAtPriceRange[0].toString().replace(',', '.'))
        : 0
    const displayDiscountPrice = compareAtPrice && lowestPrice < lowestCompareAtPrice

    return (
        <LinkWrapper href={href} passHref>
            <S.card.product.Wrapper
                compact={compact}
                sober={sober}
                className={className}
                onClick={e => {
                    if (openInNewTab) {
                        e.preventDefault()
                        window.open(href, '_blank')
                    }
                }}
            >
                <StyledPicture
                    nativeLazyLoad={picture.nativeLazyLoad}
                    src={picture.src ?? ''}
                    srcSet={picture.srcSet ?? ''}
                    alt={picture.alt ?? undefined}
                    compact={compact}
                    sober={sober}
                />
                <S.card.product.Informations>
                    {criteria && <CriterionList maxToShow={5} criteria={criteria} />}
                    <S.heading.TitleCard>{title}</S.heading.TitleCard>
                    {variantTitle && <S.copy.TextInfo>{variantTitle}</S.copy.TextInfo>}
                    {brand && <S.copy.Text1>{brand}</S.copy.Text1>}
                    <S.card.product.Prices>
                        <S.copy.TextPrice>{price}</S.copy.TextPrice>
                        {displayDiscountPrice && (
                            <S.card.product.CompareAtPrice>
                                {compareAtPrice}
                            </S.card.product.CompareAtPrice>
                        )}
                    </S.card.product.Prices>
                </S.card.product.Informations>
                {description && (
                    <S.copy.Text4 dangerouslySetInnerHTML={{ __html: description }} />
                )}
            </S.card.product.Wrapper>
        </LinkWrapper>
    )
}

export const StyledPicture = styled(Picture)<{ compact: boolean; sober: boolean }>`
    border-radius: 12px;

    img {
        transition: transform 0.3s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media screen and (${breakpoint.LAPTOP}) {
        ${S.card.product.Wrapper}:hover & {
            img {
                transform: scale(1.1);
            }
        }
    }

    ${({ compact, sober }) =>
        compact
            ? css`
                  height: 125px;
                  width: 125px;
                  flex: none;

                  ${!sober &&
                  css`
                      @media screen and (${breakpoint.TABLET}) {
                          height: 0;
                          width: 100%;
                          padding-bottom: 100%;
                      }
                  `}
              `
            : css`
                  min-width: 225px;
                  min-height: 225px;

                  @media screen and (${breakpoint.TABLET}) {
                      height: 0;
                      width: 100%;
                      padding-bottom: 100%;
                  }

                  @media screen and (${breakpoint.LAPTOP}) {
                      min-width: 215px;
                  }
              `}
`

export const ProductCard = styled(ProductCardComponent)``

type ProductCardSkeletonProps = {
    className?: string
    compact?: boolean
    sober?: boolean
}

function ProductCardSkeletonComponent({
    className,
    compact = false,
    sober = false
}: ProductCardSkeletonProps) {
    return (
        <S.card.product.Wrapper className={className} compact={compact} sober={sober}>
            <StyledPictureSkeleton as={'div'} compact={compact} sober={sober} />
            <S.card.product.Informations>
                {!sober && (
                    <S.card.criterion.Criteria>
                        <S.skeleton.Circle radius={20} />
                        <S.skeleton.Circle radius={20} />
                        <S.skeleton.Circle radius={20} />
                    </S.card.criterion.Criteria>
                )}
                <S.skeleton.Text />
                {!sober && <S.skeleton.Text size="small" width={40} />}
                <S.skeleton.Text size="small" width={30} />
            </S.card.product.Informations>
        </S.card.product.Wrapper>
    )
}

export const ProductCardSkeleton = styled(ProductCardSkeletonComponent)`
    ${S.card.criterion.Criteria} {
        ${S.skeleton.Circle} {
            margin-bottom: 7px;
            margin-right: 5px;
        }
    }
`

export const StyledPictureSkeleton = styled(StyledPicture)<{
    compact: boolean
    sober: boolean
}>`
    background-color: ${color.SAND_2};
    ${S.skeleton.skeletonAnimationRules}
`
