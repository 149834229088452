import styled from 'styled-components'
import * as breakpoint from '../../constants/breakpoints'
import * as color from '../../constants/colors'
import * as copy from '../../styles/copy'
import * as icons from '../../svg/icons'
import * as heading from '../heading'

export const Image = styled.img`
    height: 100%;
    object-fit: cover;
    position: absolute;
    transition: transform 0.3s ease-in-out;
    width: 100%;
`

export const Title = styled(heading.Title3)`
    position: relative;

    ${icons.ArrowRight} {
        transition: opacity 0.3s ease-in-out;
        position: absolute;
        right: 0px;
        bottom: 10px;
        opacity: 0;
    }
`

export const Link = styled.div`
    border-radius: 7px;
    display: block;
    height: 100%;
    overflow: hidden;
    padding-bottom: 146.67%;
    position: relative;
    width: 100%;

    // HACK: Safari messes up rendering of rasterised images (among other stuff) when scaled up
    // while having borders and hidden overflow. This forces size re-calculation, skipping the rendering glitch.
    -webkit-transform: translate3d(0, 0, 0);

    &::after {
        content: '';
        background: linear-gradient(
            180deg,
            rgba(19, 34, 42, 0) 0%,
            rgba(19, 34, 42, 0.1) 15%,
            rgba(19, 34, 42, 0.6) 30%,
            rgba(19, 34, 42, 0.7) 40%,
            rgba(19, 34, 42, 0.8) 100%
        );
        height: 200%;
        position: absolute;
        top: 0;
        transform: translateY(0%);
        transition: transform 0.3s ease-in-out;
        width: 100%;
    }

    @media screen and (${breakpoint.LAPTOP}) {
        padding-bottom: 146.603%;

        &:hover {
            &::after {
                transform: translateY(-5%);
            }
            ${Image} {
                transform: scale(1.1);
            }
        }
    }
`

export const Data = styled.div`
    bottom: 0;
    left: 0;
    padding: 15px;
    position: absolute;
    width: 100%;
`

export const Wrapper = styled.div`
    color: ${color.WHITE};
    height: 100%;
    position: relative;
    width: 100%;

    @media screen and (${breakpoint.LAPTOP}) {
        &:hover {
            cursor: pointer;

            ${icons.ArrowRight} {
                opacity: 1;
            }
        }
    }
`

export const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
    margin-bottom: 4px;
`

export const CompareAtPrice = styled(copy.Text4)`
    display: inline;
    text-decoration: line-through;
    margin-left: 10px;
`
