import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type LineGridLayoutProps = {
    size?: number
} & React.HTMLAttributes<HTMLDivElement>

function LineGridLayoutComponent({
    children,
    size,
    ...rest
}: LineGridLayoutProps): React.ReactElement {
    if (size !== undefined) {
        return (
            <S.lineGridLayout.ScrollableGrid size={size} {...rest}>
                {React.Children.map(children, child => {
                    return <S.lineGridLayout.GridItem>{child}</S.lineGridLayout.GridItem>
                })}
            </S.lineGridLayout.ScrollableGrid>
        )
    } else {
        return (
            <S.lineGridLayout.Grid {...rest}>
                {React.Children.map(children, child => {
                    return <S.lineGridLayout.GridItem>{child}</S.lineGridLayout.GridItem>
                })}
            </S.lineGridLayout.Grid>
        )
    }
}

export const LineGridLayout = styled(LineGridLayoutComponent)``
