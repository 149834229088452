var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import * as ds from '@bob/design-system';
import * as url from '@bob/url';
import { SectionBorderless } from '../Section';
import { Content } from './Content';

function MomentsComponent(_ref) {
  var homepage = _ref.homepage;

  if (Object.keys(homepage.productsByMoment).length === 0) {
    return __jsx(React.Fragment, null);
  }

  return __jsx(SectionBorderless, {
    title: "Ma journ\xE9e Bien ou Bien",
    text: "D\xE9couvrez toute notre s\xE9lection de produits selon le moment o\xF9 vous en aurez besoin !",
    footer: __jsx(Link, {
      prefetch: false,
      href: url.products({
        title: ''
      }).path,
      passHref: true
    }, __jsx(ds.ButtonAsLink, {
      autoSized: true
    }, "Parcourir le magasin"))
  }, __jsx(Content, {
    homepage: homepage
  }));
}

export var Moments = styled(MomentsComponent).withConfig({
  displayName: "Moment__Moments",
  componentId: "sc-1d1jzum-0"
})([""]);