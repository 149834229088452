import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import React from "react";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import styled from 'styled-components';
import * as url from '@bob/url';
import * as ds from '@bob/design-system';
import { Section, Text } from './Section';
export function Actus(_ref) {
  var homepage = _ref.homepage;
  return __jsx(ActusSection, {
    title: "Les actualit\xE9s",
    text: "On d\xE9crypte pour vous toute l'actu du secteur de la consommation responsable.",
    footer: __jsx(ds.ButtonAsLink, {
      autoSized: true,
      href: url.articles().path
    }, "Voir tous les articles")
  }, __jsx(Sticker, null, __jsx(ds.stickers.others.WhatsUp, {
    size: 125
  })), __jsx(ds.LineGridLayout, null, homepage.articles.map(function (article) {
    var _article$firstPublish, _article$section;

    return __jsx(ds.ArticleCard, {
      key: article.slug,
      href: url.article({
        slug: article.slug,
        title: article.title
      }).path,
      title: article.title,
      date: new Date(article.forceUpdatedAt ? article.forceUpdatedAt : (_article$firstPublish = article.firstPublishedAt) !== null && _article$firstPublish !== void 0 ? _article$firstPublish : '').toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }),
      tag: (_article$section = article.section) !== null && _article$section !== void 0 ? _article$section : undefined,
      image: _objectSpread(_objectSpread({}, article.image), {}, {
        nativeLazyLoad: true
      })
    });
  })));
}
var ActusSection = styled(Section).withConfig({
  displayName: "Actus__ActusSection",
  componentId: "sc-vj5nek-0"
})(["padding:50px 0;", "{@media screen and (", "){margin-top:20px;}}"], Text, ds.breakpoint.LAPTOP);
var Sticker = styled.div.withConfig({
  displayName: "Actus__Sticker",
  componentId: "sc-vj5nek-1"
})(["margin:-62px 0 -62px calc(80% - 114px);position:relative;z-index:1;@media not screen and (", "){display:none;}"], ds.breakpoint.LAPTOP);