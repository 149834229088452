import _extends from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import Link from 'next/link';
import * as React from 'react';
import { NextSeo } from 'next-seo';
import styled, { keyframes } from 'styled-components';
import { styles as S } from '@bob/design-system';
import * as ds from '@bob/design-system';
import * as url from '@bob/url';
import { blob, resetBlob } from '@bob/utils/blob';
import { SITE_URL } from '../../constants';
import { ProductList, Sticker } from './ProductList';
export function Store(_ref) {
  var store = _ref.store;
  var pageUrl = url.store();
  resetBlob();
  return __jsx(React.Fragment, null, __jsx(NextSeo, {
    title: "Bien ou Bien | Magasin",
    description: "Bien ou Bien s\xE9lectionne pour vous des produits biens pour les humains et la plan\xE8te",
    openGraph: {
      title: 'Bien ou Bien | Magasin',
      description: 'Bien ou Bien sélectionne pour vous des produits biens pour les humains et la planète',
      url: SITE_URL,
      images: [{
        alt: 'Bien ou Bien, le magasin où acheter des biens tout simplement bien',
        url: SITE_URL + '/assets/bob_og_image.png'
      }]
    },
    robotsProps: {
      maxImagePreview: 'large'
    }
  }), __jsx(Wrapper, null, __jsx(ds.BreadCrumbs, {
    crumbs: pageUrl.crumbs
  }), __jsx(CenteredTitle, null, "Chez Bien ou Bien, on a s\xE9lectionn\xE9 des produits qui partagent vos", ' ', __jsx(Brush, null, "valeurs\xA0!")), __jsx(ProductList, {
    momentStickers: store.momentStickers,
    productList: store.productList
  }), __jsx(CenteredTitle, null, "Toutes les cat\xE9gories"), __jsx(CategoryList, null, store.categoryList.map(function (category, i) {
    var Illustration = ds.illustrations.categories[category.icon];
    return __jsx(Link, {
      key: category.name,
      href: "".concat(url.products({
        title: ''
      }).path, "?category=").concat(category.slug),
      passHref: true,
      prefetch: false
    }, __jsx(Item, {
      order: i + 1
    }, __jsx(ds.CategoryLink, _extends({}, category, {
      blob: blob(),
      illustration: __jsx(Illustration, null),
      vertical: false
    }))));
  }))));
}
var slide = keyframes(["from{left:-1em;}to{left:130%;}"]);
var BrushBackground = ds.utils.svg.toDataUrl(ds.brush.full.FullBrush5, {
  width: 482,
  height: 91,
  fill: ds.color.PINK
});
var Brush = styled.span.withConfig({
  displayName: "store__Brush",
  componentId: "sc-42v7s6-0"
})(["display:inline-block;position:relative;&::after,&::before{position:absolute;content:'';display:block;top:-0.5em;left:-1em;bottom:-0.5em;right:-0.75em;}&::before{z-index:-1;background:", ";animation:", " 0.4s ease-in-out forwards;animation-delay:1.5s;}&::after{z-index:-2;background-image:url('", "');background-size:100%;background-repeat:no-repeat;background-position:center;}"], ds.color.SAND_1, slide, BrushBackground);
export var CenteredTitle = styled(S.heading.Title2).withConfig({
  displayName: "store__CenteredTitle",
  componentId: "sc-42v7s6-1"
})(["text-align:center;margin:0 auto;max-width:333px;@media screen and (", "){max-width:1135px;}"], ds.breakpoint.TABLET);
var Wrapper = styled(ds.Container).withConfig({
  displayName: "store__Wrapper",
  componentId: "sc-42v7s6-2"
})(["", "{margin-bottom:20px;@media screen and (", "){margin-bottom:60px;}}", "{padding-bottom:50px;padding-top:40px;@media screen and (", "){padding-bottom:80px;}}", "{position:relative;}", ":nth-child(1) ", ",", ":nth-child(3) ", "{top:42%;left:-10%;@media not screen and (", "){top:40%;left:-5px;}}", ":nth-child(2) ", "{top:-12px;left:-10%;@media not screen and (", "){left:-5px;}}", ":nth-child(4) ", "{top:-12px;right:-32px;@media not screen and (", "){right:-10px;}}"], ds.BreadCrumbs, ds.breakpoint.TABLET, ds.LineGridLayout, ds.breakpoint.TABLET, S.lineGridLayout.GridItem, S.lineGridLayout.GridItem, Sticker, S.lineGridLayout.GridItem, Sticker, ds.breakpoint.LAPTOP, S.lineGridLayout.GridItem, Sticker, ds.breakpoint.LAPTOP, S.lineGridLayout.GridItem, Sticker, ds.breakpoint.LAPTOP);
export var CategoryList = styled.div.withConfig({
  displayName: "store__CategoryList",
  componentId: "sc-42v7s6-3"
})(["display:flex;flex-direction:column;align-items:flex-start;width:100%;justify-content:space-between;margin-bottom:75px;margin-top:28px;@media screen and (", "){flex-wrap:wrap;justify-content:center;padding-left:0;padding-right:0;margin-bottom:90px;margin-top:40px;flex-direction:row;}"], ds.breakpoint.TABLET);
export var Item = styled.a.withConfig({
  displayName: "store__Item",
  componentId: "sc-42v7s6-4"
})(["@media not screen and (", "){margin:10px 0;&:first-child{margin:0 0 10px;}&:last-child{margin:10px 0 0;}}@media screen and (", "){padding:10px;width:120px;}", "{display:flex;flex-direction:row;", "{margin-left:30px;}@media screen and (", "){flex-direction:column;", "{margin-left:0;}}}"], ds.breakpoint.TABLET, ds.breakpoint.TABLET, ds.CategoryLink, S.categoryLink.Title, ds.breakpoint.TABLET, S.categoryLink.Title);