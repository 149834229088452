import _extends from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["homepage"];
import React from "react";
var __jsx = React.createElement;
import { Section } from './Section';
import { Testimonials } from '../../../../components/testimonials';
export function HomeTestimonials(_ref) {
  var homepage = _ref.homepage,
      rest = _objectWithoutProperties(_ref, _excluded);

  return __jsx(Section, _extends({}, rest, {
    title: "Histoires de cr\xE9ateurs &\xA0cr\xE9atrices"
  }), __jsx(Testimonials, {
    animated: false,
    nativeLazyLoad: true,
    items: homepage.highlights
  }));
}