var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import * as ds from '@bob/design-system';
import * as url from '@bob/url';
export function GiftCard() {
  return __jsx(Link, {
    href: url.product({
      handle: 'carte-cadeau-bien-ou-bien',
      id: 7070601576631,
      title: ''
    }).path
  }, __jsx("a", null, __jsx(GiftCardWrapper, null, __jsx("img", {
    alt: "carte cadeau Bien ou BIen",
    src: "https://cdn.shopify.com/s/files/1/0573/6579/0903/products/Carte_Cadeau_Bien_ou_Bien_550x.png?v=1641400634"
  }), __jsx(ds.styles.copy.Text2, null, "Offrez une carte cadeau Bien\xA0ou\xA0Bien"), __jsx(ds.icons.ArrowRight, null))));
}
var GiftCardWrapper = styled.div.withConfig({
  displayName: "GiftCard__GiftCardWrapper",
  componentId: "sc-1i3vmd-0"
})(["align-items:center;background-color:", ";display:flex;border-radius:12px;justify-content:center;padding:12px 0px;max-width:622px;@media screen and (", "){margin:0 auto;}", "{color:", ";margin-left:16px;margin-right:10px;@media screen and (", "){margin-left:35px;margin-right:45px;}}", "{position:relative;top:2px;@media not screen and (", "){margin-right:10px;}}&:hover{background-color:", ";", "{transform:translateX(10px);}}img{border-radius:6px;height:40px;margin-left:16px;transform:rotateZ(-6deg);@media screen and (", "){height:44px;margin-left:36px;}}"], ds.color.SAND_2, ds.breakpoint.TABLET, ds.styles.copy.Text2, ds.color.PINE_GREEN_2, ds.breakpoint.TABLET, ds.icons.ArrowRight, ds.breakpoint.TABLET, ds.color.SAND_3, ds.icons.ArrowRight, ds.breakpoint.TABLET);