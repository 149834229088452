import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as React from 'react';
import styled from 'styled-components';
import * as ds from '@bob/design-system';
import * as utils from '@bob/utils';
import * as money from '@bob/money';
export function ProductList(_ref) {
  var productList = _ref.productList,
      momentStickers = _ref.momentStickers;
  return __jsx(ds.LineGridLayout, null, productList.map(function (product, i) {
    var _product$criteria = product.criteria,
        environmentalCriteria = _product$criteria.environmental,
        socialCriteria = _product$criteria.social;
    return __jsx(React.Fragment, {
      key: product.title
    }, __jsx(ds.ProductCard, {
      key: product.id,
      brand: product.brand,
      criteria: [].concat(_toConsumableArray(environmentalCriteria.map(criterionMapper)), _toConsumableArray(socialCriteria.map(criterionMapper))),
      href: product.href,
      picture: _objectSpread(_objectSpread({}, product.picture), {}, {
        nativeLazyLoad: true
      }),
      compareAtPriceRange: product.compareAtPriceRange ? [money.format(product.compareAtPriceRange[0]), money.format(product.compareAtPriceRange[1])] : undefined,
      priceRange: [money.format(product.priceRange[0]), money.format(product.priceRange[1])],
      title: product.title
    }));
  }));
}
export var Sticker = styled.div.withConfig({
  displayName: "ProductList__Sticker",
  componentId: "sc-8rmbwn-0"
})(["height:0px;padding-bottom:41%;position:absolute;width:41%;z-index:1;@media not screen and (", "){padding-bottom:39%;width:39%;}", "{height:100%;position:absolute;width:100%;}"], ds.breakpoint.TABLET, ds.stickers.Sticker);

function criterionMapper(criterion) {
  return {
    icon: utils.symbols.getCriterionSymbolName(criterion),
    name: criterion.name
  };
}